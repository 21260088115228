var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"responsive":"","items":_vm.items,"fields":_vm.tableFields,"busy":_vm.loading,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(legal_entity)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
            name: 'show-entity',
            params: {
              slug: data.item.entite.slug ? data.item.entite.slug : '8787',
              id: data.item.entite.id,
            },
          })}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(libelle)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
            name: 'show-organisation',
            params: {
              slug: data.item.slug ? data.item.slug : '8787',
              id: data.item.id,
            },
          })}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(organisation_code)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(responsable)",fn:function(data){return [_c('TableResponsable',{attrs:{"responsable":data.item.responsable}})]}},{key:"cell(organisation_attachment)",fn:function(data){return [_c('div',{},[(data.value)?_c('b-link',{attrs:{"to":{
            name: 'show-organisation',
            params: {
              slug: data.item.slug ? data.item.slug : '8787',
              id: data.item.id,
            },
          }}},[_vm._v(_vm._s(data.value.libelle))]):_vm._e(),(!data.value.libelle)?_c('span',[_vm._v("-")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"container ml-3 justify-content-end"},[((_vm.isAdmin || _vm.isSuper) && data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItem', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1)]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }