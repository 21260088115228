<template>
  <div>
    <b-table
      responsive
      :items="items"
      :fields="tableFields"
      :busy="loading"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(legal_entity)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-entity',
              params: {
                slug: data.item.entite.slug ? data.item.entite.slug : '8787',
                id: data.item.entite.id,
              },
            })
          "
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(libelle)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-organisation',
              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(organisation_code)="data">
        <span>{{ data.value }}</span>
      </template>

      <template v-slot:cell(responsable)="data">
        <TableResponsable :responsable="data.item.responsable" />
      </template>
      <template v-slot:cell(organisation_attachment)="data">
        <div class="">
          <!--          <b-badge-->
          <!--            style="cursor: pointer; background-color: #adb5bd; border-color: #adb5bd"-->
          <!--            pill-->
          <!--            @click="-->
          <!--              $emit('showOrganisation', data.item.sousOrganisations, data.item.libelle)-->
          <!--            "-->
          <!--          >-->
          <!--            {{ data.item.sousOrganisations.length }}-->
          <!--          </b-badge>-->
          <b-link
            v-if="data.value"
            :to="{
              name: 'show-organisation',
              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            }"
            >{{ data.value.libelle }}</b-link
          >

          <span v-if="!data.value.libelle">-</span>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="container ml-3 justify-content-end">
          <b-button
            v-if="(isAdmin || isSuper) && data.value.includes('delete')"
            @click="$emit('deleteItem', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import SortedTablePlugin from "vue-sorted-table";
import Vue from "vue";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },

  data: () => ({
    sortBy: "label",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    // tableFields: [
    //   { key: "legal_entity", label: "Entité juridique", sortable: true },
    //   { key: "libelle", label: "Organisation", sortable: true },
    //   {
    //     key: "organisation_code",
    //     label: "Code organisation",
    //     sortable: true,
    //   },
    //   {
    //     key: "organisation_attachment",
    //     label: "Organisation de rattachement",
    //     sortable: true,
    //   },
    //   { key: "responsable", label: "Responsable", sortable: true },

    //   { key: "type", label: "Type", sortable: true },
    //   {
    //     key: "actions",
    //     label: "Actions",
    //     thStyle: { width: "150px !important", minWidth: "150px !important" },
    //     sortable: false,
    //   },
    // ],
    legalEntity: {
      tableItems: [],
    },
  }),
  computed: {
    ...mapGetters(["isAdmin", "isSuper"]),
    rows() {
      return this.items ? this.items.length : 0;
    },
    tableFields() {
      let tableItems = [
        { key: "legal_entity", label: "Entité juridique", sortable: true },
        { key: "libelle", label: "Organisation", sortable: true },
        {
          key: "organisation_code",
          label: "Code organisation",
          sortable: true,
        },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "responsable", label: "Responsable", sortable: true },

        { key: "type", label: "Type", sortable: true },
      ];
      if (this.isSuper)
        tableItems.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        });

      return tableItems;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },
  methods: {},

  mounted() {},
};
</script>

<style></style>
