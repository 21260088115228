var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('hr'),_c('b-table',{attrs:{"responsive":"","items":_vm.myTeamMembers,"fields":_vm.legalEntity.tableFields,"busy":_vm.loading,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(team)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
            name: 'show-team',

            params: {
              slug: data.item.slug ? data.item.slug : '8787',
              id: data.item.id,
            },
          })}}},[_vm._v(_vm._s(data.value.name))])]}},{key:"cell(responsable)",fn:function(data){return [_c('TableResponsable',{attrs:{"responsable":data.item.team.manager}})]}},{key:"cell(collaborators_number)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(data.item.team.members ? data.item.team.members.length : 0))])],1)]}},{key:"cell(organisation_attachment)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.team.organisation ? data.item.team.organisation.libelle : "")+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-end"},[(data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItem', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-10 p-0",staticStyle:{"margin-top":"-56px"}},[_c('p',{staticClass:"h2 text-secondary m-3"},[_c('i',{staticClass:"mdi mdi-account-group",staticStyle:{"font-size":"22px","color":"#072648 !important"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"18px"}},[_vm._v(" Mon équipe : ")]),_c('span',{staticStyle:{"font-siz e":"22px"}})])])
}]

export { render, staticRenderFns }