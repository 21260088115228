<template>
  <div>
    <b-table
      responsive
      :items="items"
      :fields="tableFields"
      :busy="loading"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(couvertures)="data">
        <div v-if="data.value.length > 0" class="d-flex">
          <div
            class="p-2 m-1 bg-light rounded d-flex flex-wrap align-items-center"
            style="cursor: pointer"
            v-for="(country, index) in data.value"
            :key="index"
            @click="$emit('showGeoMap', data.item.couvertures, data.item)"
          >
            <i
              :class="'flag-icon mr-1 flag-icon-' + country.countryCode"
              :title="country.countryCode"
              :id="country.countryCode"
            ></i>
            {{ country.country }}
          </div>
        </div>
        <div v-else>
          <span>n/a</span>
        </div>
      </template>
      <template v-slot:cell(libelle)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-entity',
              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
        >
          {{ data.value ? data.value : "-" }}</b-link
        >
      </template>

      <template v-slot:cell(organisations)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            @click="
              $emit(
                'showentitieOrganisation',
                data.item.id,
                data.item.libelle,
                'entitie'
              )
            "
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{ data.value ? data.value.length : 0 }}</b-badge
          >
        </div>
      </template>

      <template v-slot:cell(acteurs)="data">
        <!-- {{
          data.item.organisations
            .map((organisation) => organisation.users.length)
            .reduce((a, b) => a + b, 0)
        }} -->
        <div class="text-center">
          <b-badge
            pill
            @click="dataActeurs(data.item)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{
              data.item.organisations
                ? data.item.organisations
                    .map((organisation) => organisation.users.length)
                    .reduce((a, b) => a + b, 0)
                : 0
            }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <!--table-actions
          :actions="data.value"
          :editMode="data.item.hasOwnProperty('editMode')"
          @cancelItem="$emit('removeEditableStrategicGoal', data.index)"
          @showItem="$emit('showItem')"
          @editItem="$emit('editItem')"
        /-->
        <div
          v-if="isAdmin || isSuper"
          class="container ml-3 justify-content-end"
        >
          <b-button
            v-if="data.value.includes('delete')"
            @click="$emit('deleteItem', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <div v-if="showActeurs" to="modals-xyz-548">
      <Modal @close="showActeurs = !showActeurs">
        <ActeursList :items="selectedActeurs" />
      </Modal>
    </div>
  </div>
</template>

<script>
// import { tableItems } from '../../../dashboard/constants';
import SortedTablePlugin from "vue-sorted-table";
import ActeursList from "./ActeursList";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { ActeursList, Modal },
  data: () => ({
    showActeurs: false,
    selectedActeurs: [],
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    legalEntity: {
      tableFields: [
        { key: "libelle", label: "Entité", sortable: true },
        { key: "couvertures", label: "Couverture", sortable: true },
        { key: "organisations", label: "Organisations", sortable: true },
        { key: "acteurs", label: "Acteurs", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [],
    },
  }),
  computed: {
    ...mapGetters(["isAdmin", "isSuper"]),

    rows() {
      return this.items ? this.items.length : 0;
    },
    tableFields() {
      let tableItems = [
        { key: "libelle", label: "Entité", sortable: true },
        { key: "couvertures", label: "Couverture", sortable: true },
        { key: "organisations", label: "Organisations", sortable: true },
        { key: "acteurs", label: "Acteurs", sortable: true },
      ];
      if (this.isSuper)
        tableItems.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        });

      return tableItems;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },

  methods: {
    dataActeurs(item) {
      let acteurs = [];
      item.organisations
        ? item.organisations.map((organisation) =>
            organisation.users.map((user) => acteurs.push(user))
          )
        : 0;
      if (acteurs.length) {
        var user = {
          name: item.libelle,
          user: acteurs,
        };
        this.selectedActeurs = user;
        this.showActeurs = !this.showActeurs;
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
