var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"responsive":"","items":_vm.items,"fields":_vm.tableFields,"busy":_vm.loading,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(couvertures)",fn:function(data){return [(data.value.length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((data.value),function(country,index){return _c('div',{key:index,staticClass:"p-2 m-1 bg-light rounded d-flex flex-wrap align-items-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('showGeoMap', data.item.couvertures, data.item)}}},[_c('i',{class:'flag-icon mr-1 flag-icon-' + country.countryCode,attrs:{"title":country.countryCode,"id":country.countryCode}}),_vm._v(" "+_vm._s(country.country)+" ")])}),0):_c('div',[_c('span',[_vm._v("n/a")])])]}},{key:"cell(libelle)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
            name: 'show-entity',
            params: {
              slug: data.item.slug ? data.item.slug : '8787',
              id: data.item.id,
            },
          })}}},[_vm._v(" "+_vm._s(data.value ? data.value : "-"))])]}},{key:"cell(organisations)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.$emit(
              'showentitieOrganisation',
              data.item.id,
              data.item.libelle,
              'entitie'
            )}}},[_vm._v(_vm._s(data.value ? data.value.length : 0))])],1)]}},{key:"cell(acteurs)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){return _vm.dataActeurs(data.item)}}},[_vm._v(_vm._s(data.item.organisations ? data.item.organisations .map((organisation) => organisation.users.length) .reduce((a, b) => a + b, 0) : 0))])],1)]}},{key:"cell(actions)",fn:function(data){return [(_vm.isAdmin || _vm.isSuper)?_c('div',{staticClass:"container ml-3 justify-content-end"},[(data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItem', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1):_vm._e()]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.showActeurs)?_c('div',{attrs:{"to":"modals-xyz-548"}},[_c('Modal',{on:{"close":function($event){_vm.showActeurs = !_vm.showActeurs}}},[_c('ActeursList',{attrs:{"items":_vm.selectedActeurs}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }