<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="row">
        <div class="col-12">
          <div class="bg-white p-2">
            <b-tabs
              :value="$store.state.tabs.activeTab"
              @changed="activeTab = $store.state.tabs.activeTab"
              @activate-tab="
                (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab
                v-if="
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isCollaborator ||
                  isArchitect ||
                  isPo
                "
                title="Entités juridiques"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <div class="my-auto mr-2">
                        <h4 class="card-title my-3">Liste des entités</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-md-6
                      d-flex
                      flex-
                      justify-content-end
                      align-btn-table-2
                    "
                  >
                    <div class="col-12 col-md-4 my-auto">
                      <b-form-input
                        class="ml-2"
                        v-model="entiteLibelle"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <button
                      type="button"
                      class="
                        btn
                        h1
                        my-auto
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center
                      "
                      @click="$router.push({ name: 'add-entity' })"
                      style="
                        background-color: #007bffff !important;
                        border-color: #007bffff !important;
                      "
                      v-if="isAdmin || isSuper"
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Nouvelle entité
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>

                  <div class="col-12">
                    <legal-entity-table
                      :items="entitiesItems"
                      :loading="loadingentity"
                      @editItem="$router.push({ name: 'edit-entity' })"
                      @showItem="$router.push({ name: 'show-entity' })"
                      @showGeoMap="handleGeoMap"
                      @deleteItem="deleteEntity"
                      @showentitieOrganisation="showentitieOrganisation"
                    />
                  </div>
                </div>
              </b-tab>

              <b-tab
                v-if="
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isCollaborator ||
                  isArchitect ||
                  isPo
                "
                title="Organisations"
              >
                <div class="row mb-3">
                  <div class="col-12 col-md-12">
                    <div class="d-flex flex-warap">
                      <div class="col-md-2 p-0">
                        <h4 class="card-title my-3">Liste des organisations</h4>
                      </div>
                      <div
                        class="d-flex col-md-10 p-0 ml-auto justify-content-end"
                      >
                        <div class="pr-1 my-auto">
                          <b-form-input
                            style="width: 160px !important"
                            placeholder="Entité"
                            v-model="entitie"
                          ></b-form-input>
                        </div>
                        <div class="pr-1 my-auto">
                          <b-form-input
                            style="width: 160px !important"
                            type="number"
                            v-model="niveau"
                            placeholder="Niveau"
                          ></b-form-input>
                        </div>
                        <div class="pr-1 my-auto">
                          <b-form-input
                            style="width: 160px !important"
                            placeholder="Organisation"
                            v-model="libelle"
                          ></b-form-input>
                        </div>
                        <div class="pr-1 my-auto">
                          <v-select
                            style="width: 160px !important"
                            label="value"
                            v-model="sousorganisation"
                            :options="sousOrganisation"
                            :reduce="(responsable) => responsable.id"
                            placeholder="Sous organisations"
                          >
                            <template #no-options> Liste vide </template>
                          </v-select>
                        </div>
                        <div class="pr-1 my-auto">
                          <v-select
                            :options="typeorg"
                            style="width: 160px !important"
                            placeholder="Type"
                            v-model="typeorganisation"
                          >
                            <template #no-options> Liste vide </template>
                          </v-select>
                        </div>
                        <button
                          v-if="isAdmin || isSuper"
                          type="button"
                          class="btn h1 my-auto test-btn btn-secondary px-2"
                          @click="$router.push({ name: 'add-organisation' })"
                          style="
                            background-color: #007bffff !important;
                            border-color: #007bffff !important;
                          "
                        >
                          <i class="mdi mdi-plus mr-1"></i>Créer organisation
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <organisation-table
                      :items="organisations"
                      :loading="loadingData"
                      @showItem="$router.push({ name: 'show-organisation' })"
                      @deleteItem="deleteItem"
                      @editItem="$router.push({ name: 'edit-organisation' })"
                      @showOrganisation="showOrganisation"
                    />
                  </div>
                </div>
              </b-tab>

              <b-tab
                v-if="
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isCollaborator ||
                  isArchitect ||
                  isPo
                "
                title="Équipes"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <div>
                        <h4 class="card-title my-3">Liste des équipes</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-md-6
                      d-flex
                      justify-content-end
                      align-btn-table-2
                    "
                  >
                    <div class="col-12 col-md-4 my-auto">
                      <b-form-input
                        class="ml-2"
                        v-model="teamLibelle"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <button
                      v-if="isAdmin || isSuper"
                      type="button"
                      class="
                        btn
                        h1
                        my-auto
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center
                      "
                      @click="$router.push({ name: 'add-team' })"
                      style="
                        background-color: #007bffff !important;
                        border-color: #007bffff !important;
                      "
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Nouvelle équipe
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                  <div class="col-12">
                    <!-- {{ datateam }} -->
                    <team-table
                      :items="teamsItems"
                      :loading="loadingActeur"
                      @deleteItem="deleteTeam"
                      @showItem="$router.push({ name: 'show-team' })"
                      @editItem="$router.push({ name: 'edit-team' })"
                    />
                  </div>
                </div>
              </b-tab>

              <b-tab
                v-if="
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isCollaborator ||
                  isArchitect ||
                  isPo ||
                  isGouvernance
                "
                title="Collaborateurs"
              >
                <div class="row">
                  <div class="col-12 col-sm-3 my-3">
                    <h4 class="card-title my-auto">Liste des collaborateurs</h4>
                  </div>

                  <div
                    class="col-12 col-sm-9 d-flex flex-wrap justify-content-end"
                  >
                    <div class="col-12 col-sm-3">
                      <b-form-input
                        class="ml-2"
                        v-model="search"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <button
                      type="button"
                      v-if="isAdmin || isSuper"
                      class="
                        btn
                        h1
                        mb-2
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center align-btn-table-ben
                      "
                      @click="$router.push({ name: 'add-actor' })"
                      style="
                        background-color: rgb(0, 123, 255) !important;
                        border-color: rgb(0, 123, 255) !important;
                      "
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Nouvel acteur
                    </button>
                    <button
                      type="button"
                      class="
                        btn
                        h1
                        mb-2
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center align-btn-table-ben
                      "
                      style="
                        background-color: rgb(0, 123, 255) !important;
                        border-color: rgb(0, 123, 255) !important;
                      "
                      @click="Monequipe"
                    >
                      Mon équipe
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ActorsTable
                      :items="usersData"
                      :loading="loadingBen"
                      @addFunction="(item) => addFunction(item)"
                      @showItem="$router.push({ name: 'show-actor' })"
                      @editItem="$router.push({ name: 'edit-actor' })"
                      @deleteItemActeur="deleteItemActeur"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Bénéficiaires" v-if="isAdmin || isSuper">
                <div class="row">
                  <div class="col-12 col-sm-3 my-3">
                    <h4 class="card-title my-auto">Liste des bénéficiaires</h4>
                  </div>
                  <div
                    class="col-12 col-sm-9 d-flex flex-wrap justify-content-end"
                  >
                    <div class="col-12 col-sm-3">
                      <b-form-input
                        class="ml-2"
                        v-model="name"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <button
                      type="button"
                      class="
                        btn
                        h1
                        mb-2
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center align-btn-table-ben
                      "
                      @click="$router.push({ name: 'add-payee' })"
                      style="
                        background-color: rgb(0, 123, 255) !important;
                        border-color: rgb(0, 123, 255) !important;
                      "
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Nouveau bénéficiaire
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <payeeTable
                      :items="selecteddataben"
                      :loading="loadingData"
                      @deleteItemBenificaire="deleteItemBenificaire"
                      @showItem="$router.push({ name: 'show-payee' })"
                      @editItem="$router.push({ name: 'edit-payee' })"
                    />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>

    <div v-if="showGeoMap" to="modals-xyz-548">
      <Modal @close="showGeoMap = !showGeoMap">
        <MapBoxEntitie :countries="selectedCountries" :contr="contr" />
      </Modal>
    </div>
    <div v-if="organisation" to="modals-xyz-548">
      <Modal @close="organisation = !organisation">
        <OrganisationList :items="selectedOrganisation" />
      </Modal>
    </div>
    <div v-if="entiteorganisation" to="modals-xyz-548">
      <Modal @close="entiteorganisation = !entiteorganisation">
        <EntitieOrganisationList :items="selectedentitieOrganisation" />
      </Modal>
    </div>

    <!-- <map-box /> -->

    <div v-if="showequipe" to="modals-xyz-548">
      <Modal @close="showequipe = !showequipe">
        <Monequipe :items="usersData" />
      </Modal>
    </div>
  </div>
</template>

<script>
import OrganisationList from "./components/OrganisationList.vue";
import Monequipe from "./components/Monequipe";
import LegalEntityTable from "./components/LegalEntityTable.vue";
import OrganisationTable from "./components/OrganisationTable.vue";
import TeamTable from "./components/TeamTable.vue";
import ActorsTable from "./components/ActorsTable";
import payeeTable from "./components/PayeeTable";

//import GeoMap from "./components/GeoMap.vue";
import Modal from "@/components/custom/Modal.vue";
import MapBoxEntitie from "@/components/custom/MapBoxEntitie.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import EntitieOrganisationList from "./components/EntitieOrganisationList";
import { concat } from "lodash";

export default {
  components: {
    EntitieOrganisationList,
    Monequipe,
    LegalEntityTable,
    OrganisationTable,
    TeamTable,
    OrganisationList,
    ActorsTable,
    payeeTable,
    //  GeoMap,
    Modal,
    MapBoxEntitie,
  },
  data: () => ({
    showGeoMap: false,
    selectedOrganisation: [],
    contr: null,
    entiteorganisation: false,
    organisation: false,
    selectedCountries: null,
    selecteddata: null,
    loadingData: true,
    loading: true,
    showequipe: false,
    dataentity: [],
    datateam: [],
    activeTab: null,
    entitie: "",
    type: "",
    typeorganisation: null,
    niveau: "",
    libelle: "",
    sousorganisation: "",
    selectedentitieOrganisation: [],
    selecteddataben: null,
    search: "",
    name: "",
    loadingActeur: true,
    loadingBen: true,
    loadingentity: true,
    entiteLibelle: "",
    teamLibelle: "",
  }),
  methods: {
    Monequipe() {
      this.showequipe = true;
    },
    deleteItemBenificaire: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("beneficiare/deleteBeneficiare", id).then(() => {
            this.$store
              .dispatch("beneficiare/fetchAllBeneficiares")
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    deleteItemActeur: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("users/deleteUsers", id))
            this.selecteddata = this.selecteddata.filter(
              (item) => item.id !== id
            );

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
    showOrganisation(members, name, test) {
      if (test == "entitie") {
        if (members.length > 0) {
          members = {
            organisation: members,
            name: name,
            label: true,
          };
          this.selectedOrganisation = members;
          this.organisation = true;
        }
      } else {
        if (members.length > 0) {
          members = {
            organisation: members,
            name: name,
          };
          this.selectedOrganisation = members;
          this.organisation = true;
        }
      }
    },
    showentitieOrganisation(id, name) {
      this.$store.dispatch("entity/fetchOrganisation", id).then(() => {
        if (this.ORGANISATION.organisations.length > 0) {
          var members = {
            organisation: this.ORGANISATION,
            name: name,
            label: true,
          };
          this.selectedentitieOrganisation = members;
          this.entiteorganisation = true;
        }
      });
    },
    handleGeoMap(countries, name) {
      var table = ["in", "iso_3166_1"];
      var contr = [];
      for (var i = 0; i < countries.length; i++) {
        contr[i] = countries[i].countryCode.toUpperCase();
      }
      for (var j = 0; j < contr.length; j++) {
        if (contr[j] == "MA") contr.push("EH");
      }
      table = concat(table, contr);
      var data = {
        name: name,
        couverture: countries.map((item) => ({
          ...item,
          name: name,
        })),
      };

      this.selectedCountries = data;
      this.contr = table;
      this.showGeoMap = !this.showGeoMap;
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("organisation/deleteOrganisation", id)) {
            this.selecteddata = this.selecteddata.filter(
              (item) => item.id !== id
            );
            Swal.fire("Supprimé!", "", "success");
          }
        }
      });
    },
    deleteEntity: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("entity/deleteEntity", id)) {
            this.dataentity = this.dataentity.filter((item) => item.id !== id);
            Swal.fire("Supprimé!", "", "success");
          }
        }
      });
    },
    deleteTeam: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("teams/deleteTeam", id)) {
            this.datateam = this.datateam.filter((item) => item.id !== id);
            Swal.fire("Supprimé!", "", "success");
          }
        }
      });
    },
  },
  created() {
    this.$store.dispatch("entity/fetchAllEntities").then(() => {
      this.loadingentity = false;
    });
    this.$store.dispatch("type/fetchOrganisationTypes");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("teams/fetchAllTeams");
    this.$store.dispatch("beneficiare/fetchAllBeneficiares").then(() => {
      this.loadingBen = false;
    });
    this.$store.dispatch("users/fetchAllUsers").then(() => {
      this.loadingActeur = false;
    });
  },

  computed: {
    ...mapGetters("beneficiare", ["Beneficiares"]),
    ...mapGetters("users", ["USERS"]),
    usersData() {
      return this.USERS.map((ben) => ({
        ...ben,
        actor: ben.firstname + " " + ben.lastname,
        hierarchy_level: ben.hiearchie,
        legal_entity: ben.organisationRatachement
          ? ben.organisationRatachement.entite
          : "",
        organisation_attachment: ben.organisationRatachement
          ? ben.organisationRatachement
          : "",
        account: ben.isActif,
        actions: ["show", "delete"],
      })).filter(
        (processus) =>
          processus.firstname.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1 ||
          processus.lastname.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
      );
    },
    ...mapGetters("teams", ["TEAMS"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("entity", ["ORGANISATION"]),
    ...mapGetters([
      "isManager",
      "isSuper",
      "isCollaborator",
      "isArchitect",
      "isPo",
      "isAdmin",
      "isGouvernance",
    ]),
    sousOrganisation() {
      var data = this.ORGANISATIONS.map((org) => ({
        id: org.id,
        value: org.libelle,
      }));

      return data;
    },
    organisations() {
      return this.ORGANISATIONS.map((orgnisation) => ({
        ...orgnisation,
        legal_entity: orgnisation.entite ? orgnisation.entite.libelle : "-",
        organisation_attachment: orgnisation.parent ? orgnisation.parent : "-",
        organisation_code: orgnisation.code,
        actions: ["show", "delete"],
      }))
        .filter(
          (organisation) =>
            (organisation && organisation.libelle
              ? organisation.libelle
                  .toLowerCase()
                  .indexOf(this.libelle.toLowerCase()) > -1
              : false) &&
            (organisation.entite
              ? organisation.entite.libelle
                  .toLowerCase()
                  .indexOf(this.entitie.toLowerCase()) > -1
              : false) &&
            organisation.type
              .toLowerCase()
              .indexOf(
                this.typeorganisation ? this.typeorganisation.toLowerCase() : ""
              ) > -1 &&
            organisation.niveau
              .toString()
              .toLowerCase()
              .indexOf(this.niveau.toLowerCase()) > -1
        )
        .filter((org) => {
          if (this.sousorganisation) {
            if (org.sousOrganisations.length) {
              return org.sousOrganisations.filter(
                (orgr) => orgr.id === this.sousorganisation
              ).length;
            } else return false;
          } else return true;
        });
    },
    ...mapGetters("type", ["ORG_TYPES"]),

    typeorg() {
      return this.ORG_TYPES;
    },
    entitiesItems() {
      return this.dataentity.filter(
        (entite) =>
          entite.libelle
            .toLowerCase()
            .indexOf(this.entiteLibelle.toLowerCase()) > -1
      );
    },
    teamsItems() {
      return this.datateam.filter(
        (team) =>
          team.name.toLowerCase().indexOf(this.teamLibelle.toLowerCase()) > -1
      );
    },
  },
  watch: {
    Beneficiares: function () {
      this.selecteddataben = this.Beneficiares.map((ben) => ({
        ...ben,
        payee: ben.name,
        actions: ["show", "delete"],
      }));
    },
    name() {
      this.selecteddataben = this.Beneficiares.filter(
        (capacite) =>
          capacite.name.toLowerCase().indexOf(this.name.toLowerCase()) > -1
      ).map((ben) => ({
        ...ben,
        payee: ben.name,
        actions: ["show", "delete"],
      }));
    },
    ENTITIES: function () {
      this.dataentity = this.ENTITIES.map((ent) => ({
        ...ent,
        actions: ["show", "delete"],
      }));
    },
    TEAMS: function () {
      this.datateam = this.TEAMS.map((team) => ({
        ...team,
        organisation_attachment: team.organisation
          ? team.organisation.libelle
          : name
          ? name
          : "-",
        actions: ["show", "delete"],
      }));
      this.loadingData = false;
    },
  },
};
</script>

<style></style>
