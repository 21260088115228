<template>
  <div class="row" style="margin-top: -70px">
    <div class="col-10 p-0">
      <p class="h2 text-secondary m-3">
        <i
          style="font-size: 22px; color: #072648 !important"
          class="mdi mdi-domain"
        ></i>
        <span
          v-if="items.label"
          style="font-size: 18px"
          class="font-weight-light"
        >
          Entité :
          <span style="font-size: 22px" class="font-weight-bold">
            {{ items.name }}
          </span>
        </span>
        <span v-else style="font-size: 18px" class="font-weight-light">
          <!-- Les Organisation de rattachement du Organisation : {{ items.name }} -->
          Organisation : {{ items.name }}
        </span>
      </p>
    </div>
    <div class="col-12" style="margin-top: -20px">
      <h4 class="card-title mt-4 mb-1">
        Liste des organisations de rattachement :
      </h4>
      <hr />
    </div>
    <div class="col-12">
      <b-table
        responsive
        :items="items.organisation.organisations"
        :fields="members.tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        bordered
        striped
      >
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <template v-slot:cell(libelle)="data">
          <b-link
            :to="{
              name: 'show-organisation',
              params: {
                slug: data.item.slug ? data.item.slug : '61f1159652a44',
                id: data.item.id,
              },
            }"
            >{{ data.value ? data.value : "-" }}</b-link
          >
        </template>
        <template v-slot:cell(code)="data">
          <span>{{ data.value ? data.value : "-" }}</span>
        </template>
        <template v-slot:cell(niveau)="data">
          <span>{{ data.value ? data.value : "-" }}</span>
        </template>
        <template v-slot:cell(sousOrganisations)="data">
          <div class="text-center">
            <b-badge
              pill
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
            >
              {{ data.value ? data.value.length : 0 }}
            </b-badge>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
    },
  },
  computed: {
    rows() {
      return this.items.organisation.organisations.length;
    },
  },
  data: () => ({
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    members: {
      tableItems: [],
      tableFields: [
        { key: "libelle", label: "Libellé", sortable: true },
        {
          key: "code",
          label: "Code organisation",
          sortable: true,
        },
        {
          key: "sousOrganisations",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "Niveau", sortable: true },

        { key: "type", label: "Type", sortable: true },
      ],
    },
  }),
};
</script>

<style>
.sub-modal[data-v-23747a02] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 80%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: auto;
  height: auto;
}
</style>
