<template>
  <div>
    <div class="col-10 p-0" style="margin-top: -56px">
      <p class="h2 text-secondary m-3">
        <i
          style="font-size: 22px; color: #072648 !important"
          class="mdi mdi-account-group"
        ></i>
        <span style="font-size: 18px" class="font-weight-light">
          Mon équipe :
        </span>

        <span style="font-siz e: 22px"></span>
      </p>
    </div>
    <hr />
    <!-- {{AuthenticatedUser}} -->
    <!-- {{ myTeamMembers }} -->
    <b-table
      responsive
      :items="myTeamMembers"
      :fields="legalEntity.tableFields"
      :busy="loading"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(team)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-team',

              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
          >{{ data.value.name }}</b-link
        >
      </template>
      <template v-slot:cell(responsable)="data">
        <TableResponsable :responsable="data.item.team.manager" />
      </template>
      <template v-slot:cell(collaborators_number)="data">
        <div class="text-center">
          <b-badge
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            >{{
              data.item.team.members ? data.item.team.members.length : 0
            }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(organisation_attachment)="data">
        <span>
          {{
            data.item.team.organisation
              ? data.item.team.organisation.libelle
              : ""
          }}
        </span>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-flex justify-content-end">
          <b-button
            v-if="data.value.includes('delete')"
            @click="$emit('deleteItem', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import SortedTablePlugin from "vue-sorted-table";
import Vue from "vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});
export default {
  components: {
    TableResponsable,
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    legalEntity: {
      tableFields: [
        { key: "team", label: "Equipe", sortable: true },
        {
          key: "responsable",
          label: "Responsable",
          sortable: true,
        },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
      ],
    },
  }),
  created() {
    this.$store.dispatch("teams/fetchAllTeams");
  },
  computed: {
    ...mapGetters(["AuthenticatedUser"]),
    ...mapGetters("teams", ["TEAMS"]),

    rows() {
      return this.items.length;
    },
    members() {
      return this.items;
    },
    myTeamMembers() {
      return this.items
        .filter(
          (item) =>
            item.team &&
            this.AuthenticatedUser.team &&
            item.team.id === this.AuthenticatedUser.team.id
        )
        .map((item) => ({
          ...item,
          team: this.TEAMS.find(
            (team) => team["@id"] === this.AuthenticatedUser.team["@id"]
          ),
        }));
    },
  },
  props: {
    items: Array,
    loading: Boolean,
  },
  whatch: {},
};
</script>

<style>
</style>
