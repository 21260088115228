<template>
  <div>
    <b-table
      responsive
      :items="items"
      :fields="payee.tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      :busy="loading"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(payee)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-payee',
              params: {
                slug: data.item.slug,
                id: data.item.id,
              },
            })
          "
          >{{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="container ml-3 justify-content-end">
          <b-button
            v-if="data.value.includes('edit')"
            @click="$emit('editItem', data.item)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-pencil-outline"></i
          ></b-button>
          <b-button
            v-if="data.value.includes('delete')"
            @click="$emit('deleteItemBenificaire', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    datatable: [],
    payee: {
      tableFields: [
        { key: "payee", label: "Bénéficiaire", sortable: true },
        { key: "type", label: "Type", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
        },
      ],
      tableItems: [
        {
          payee: "Clients",
          type: "Interne",
          actions: ["show", "edit", "delete"],
        },
        {
          payee: "Partenaires",
          type: "Externe",
          actions: ["show", "edit", "delete"],
        },
        {
          payee: "Fornisseurs",
          type: "Externe",
          actions: ["show", "edit", "delete"],
        },
        {
          payee: "Collaborateurs",
          type: "Interne",
          actions: ["show", "edit", "delete"],
        },
      ],
    },
    actions: ["show", "delete"],
  }),
  props: {
    items: Array,
    loading: Boolean,
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
  },
  watch: {},
};
</script>

<style></style>
