<template>
  <div>
    <b-table
      responsive
      :items="items"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      :busy="loading"
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(actor)="data">
        <b-link
          v-if="data.value"
          @click="
            $router.push({
              name: 'show-actor',
              params: {
                slug: data.item.slug ? data.item.slug : '61f115964d33c',
                id: data.item.id,
              },
            })
          "
          >{{ data.value ? data.value : "-" }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(function)="data">
        <div>
          <span>{{ data.value ? data.value : "-" }}</span>
          <!--          <b-form-input-->
          <!--          v-else-->
          <!--            type="text"-->
          <!--            @keyup.enter="(e) => addFunction({itemIndex: data.index, value:e.target.value})"-->
          <!--          ></b-form-input>-->
        </div>
      </template>
      <template v-slot:cell(account)="data">
        <b-badge v-if="data.value" variant="success">Actif</b-badge>
        <b-badge v-else variant="danger">Inactif</b-badge>
      </template>
      <template v-slot:cell(type)="data">
        <span>{{ data.value ? data.value : "-" }}</span>
      </template>

      <template v-slot:cell(legal_entity)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-entity',
            params: {
              slug: data.value.slug ? data.value.slug : '8787',
              id: data.value.id,
            },
          }"
          >{{ data.value ? data.value.libelle : "-" }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(organisation_attachment)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-organisation',
            params: {
              slug: data.value.slug ? data.value.slug : '8787',
              id: data.value.id,
            },
          }"
          >{{ data.value ? data.value.libelle : "-" }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(hierarchy_level)="data">
        <span>{{ data.value ? data.value : "-" }}</span>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="container ml-3 justify-content-end">
          <b-button
            v-if="
              isSuper &&
              AuthenticatedUser.id != data.item.id &&
              data.value.includes('delete')
            "
            @click="$emit('deleteItemActeur', data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    actors: {
      tableFields: [
        { key: "actor", label: "Acteur", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "account", label: "Compte", sortable: true },
        { key: "legal_entity", label: "Entité juridique", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        {
          key: "hierarchy_level",
          label: "Niveau Hiérarchique",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [],
    },
  }),
  props: {
    items: Array,
    loading: Boolean,
  },
  computed: {
    ...mapGetters(["isSuper", "AuthenticatedUser"]),

    rows() {
      return this.items ? this.items.length : 0;
    },
    tableFields() {
      let tableItems = [
        { key: "actor", label: "Acteur", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "account", label: "Compte", sortable: true },
        { key: "legal_entity", label: "Entité juridique", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        {
          key: "hierarchy_level",
          label: "Niveau Hiérarchique",
          sortable: true,
        },
      ];
      if (this.isSuper)
        tableItems.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        });

      return tableItems;
    },
  },
  methods: {
    addFunction(item) {
      this.actors.tableItems[item.itemIndex].function = item.value;
    },
  },
};
</script>

<style></style>
