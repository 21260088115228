<template>
  <div id="content">
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :zoom="zoom"
      :center="center"
      ref="map"
      class="default-map-xl"
    >
      <MglNavigationControl />
      <MglMarker
        v-for="(country, index) in countries.couverture"
        :key="index"
        :coordinates="{ lat: country.lat, lng: country.lng }"
        :closeButton="false"
        :closeOnClick="false"
      >
        <v-icon
          slot="marker"
          @mouseenter="targetedCountry = index"
          @mouseleave="targetedCountry = null"
        >
          <!-- <div class="frame"> -->
          <div>
            <i
              :class="
                'flag-icon flag-icon-' + country.countryCode.toLowerCase()
              "
            ></i>
          </div>
        </v-icon>

        <!-- <MglPopup :onlyText="true" :showed="targetedCountry === index">

          <strong style="size:120px">{{ country.name.libelle }}</strong>

        </MglPopup> -->
      </MglMarker>
      <MglFullscreenControl position="top-left" :container="containerElement" />
      <MglGeojsonLayer
        type="fill"
        sourceId="country-boundaries"
        :source="departementsSource"
        layerId="country-boundaries-id"
        :layer="departementsContoursLayer"
      />
    </MglMap>
  </div>
</template>

<style scoped>
.default-map-xl {
  width: 100% !important;
  height: 80vh !important;
  /* background: green ; */
}

.test {
  border-radius: 2rem;
  position: relative;
}
</style>
<style lang="scss">
.mb-opacity-0 {
  opacity: 0;
}

.mapboxgl-popup-content {
  background-color: #ffffff !important;
  width: auto !important;
  height: auto !important;
}

.mapboxgl-ctrl {
  display: none !important;
}
</style>
<script>
// import {MglPopup} from "vue-mapbox";
import {
  MglMap,
  MglFullscreenControl,
  MglGeojsonLayer,
  MglMarker,
  MglNavigationControl,
} from "vue-mapbox";
import worldCountries from "@/assets/countries/worldCountries.json";
export default {
  components: {
    MglMap,
    MglFullscreenControl,
    MglGeojsonLayer,
    // MglPopup,
    MglMarker,
    MglNavigationControl,
  },

  props: {
    countries: [],
    contr: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: ["in", "iso_3166_1"],
    },
  },

  data() {
    //    let data = this.cont;
    return {
      accessToken:
        "pk.eyJ1Ijoib3Vzc2FtYW1vdXRpYSIsImEiOiJja3lsZXZmODUzNW5tMndvODBkbGRuZ3EyIn0.3crdq2xwtwl1fF-3AJIT_w",
      mapStyle: "mapbox://styles/oussamamoutia/ckywtcztm000u14ltadeq6xic/draft",
      zoom: 1,
      center: [0, 30],
      containerElement: null,
      worldCountries: worldCountries,
      // showPopup : false,
      targetedCountry: null,
      x: null,
      departementsSource: {
        id: "country-boundaries",
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      },

      departementsContoursLayer: {
        "source-layer": "country_boundaries",
        filter: this.contr,
        type: "fill",
        paint: {
          "fill-color": "#38ff72",
        },
      },
    };
  },
  mounted() {
    this.containerElement = document.getElementById("content");
  },

  // created(){
  // }
};

let mbLogo = window.document.querySelectorAll(".mapboxgl-ctrl");

if (mbLogo.length > 0) {
  mbLogo.forEach((it) => {
    it.remove();
  });
}
</script>
<style>
.frame {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #1dbf4d;
  border: 3px solid #1dbf4d;
  border-radius: 50%;
}
</style>
